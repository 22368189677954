import React from 'react';
import {Switch, Route, BrowserRouter as Router} from 'react-router-dom';
import {Header} from './container/header/header';
import {HomeComponent} from './container/home/home';
import {Contact} from './container/contact/contact';
import {About} from './container/about/about';
import {Footer} from './container/footer/footer';
import {Notification} from './container/notification/notification';
import {LocaleProvider} from './container/locale/localeProvider';
import {Helmet} from "react-helmet";
import {getLanguageLocaleFromUrl} from "./container/util/languageUtils";
import {defaultLocale} from "./locales/messages";
import {ProductsComponent} from "./container/products/products";
import {ProductComponent} from "./container/product/product";
import ScrollToTop from "react-scroll-to-top";
import './App.scss';
import {SubcategoryComponent} from "./container/subcategory/subcategory";
import {CategoryComponent} from "./container/category/category";

class App extends React.PureComponent {

    get getTags() {
        let locale = getLanguageLocaleFromUrl();

        if (locale === undefined || locale === '') {
            locale = defaultLocale['value'];
        }

        if (locale === 'UZ' || locale === 'uz') {
            return (
                <Helmet htmlAttributes={{lang: 'uz'}}>
                    <meta charSet="utf-8"/>
                    <title>Barso &#8212; Barsodan Toshkentdagi metall polka va reshotkalar</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
                    <meta name="description"
                          content="Bizda sifatli metal polka, reshotkalar, va metaldan bo'lsan mahsulotlarni topshingiz mumkin. Hammasini O'zbekiston va Toshkent bo'yicha yetkazib beramiz."/>
                    <meta name="keywords"
                          content="metall,polka,reshotkalar,seftlar,metal,kabenitlar,stoll,stul"/>

                    <link rel="canonical" href="https://barso.uz/"/>

                    <meta property="og:locale" content="uz_UZ"/>
                    <meta property="og:type" content="article"/>
                    <meta property="og:title" content="Barso &#8212; Barsodan Toshkentdagi metall polka va reshotkalar"/>
                    <meta property="og:description"
                          content="Bizda sifatli metal polka, reshotkalar, va metaldan bo'lsan mahsulotlarni topshingiz mumkin. Hammasini O'zbekiston va Toshkent bo'yicha yetkazib beramiz."/>
                    <meta property="og:url" content="https://barso.uz/"/>
                    <meta property="og:site_name" content="barso.uz"/>
                    <meta property="article:published_time" content="2022-07-14T15:09:21+00:00"/>
                    <meta property="article:modified_time" content="2022-10-08T10:47:07+00:00"/>
                    <meta name="author" content="Barso.uz"/>
                </Helmet>
            );
        }

        if (locale === 'US' || locale === 'us') {
            return (
                <Helmet htmlAttributes={{lang: 'en'}}>
                    <meta charSet="utf-8"/>
                    <title>Barso &#8212; Metal shelves in Tashkent from Barso</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
                    <meta name="description"
                          content="Metal shelves in Tashkent from Barso with high quality metallregale, metal shelf, metal storage units, metal barriers and metal fences"/>
                    <meta name="keywords"
                          content="metal,shelf,storage,units,metal,barriers,metal,fences"/>

                    <link rel="canonical" href="https://barso.uz/"/>

                    <meta property="og:locale" content="en_US"/>
                    <meta property="og:type" content="article"/>
                    <meta property="og:title" content="Barso &#8212; Metal shelves in Tashkent from Barso"/>
                    <meta property="og:description"
                          content="Metal shelves in Tashkent from Barso with high quality metallregale, metal shelf, metal storage units, metal barriers and metal fences"/>
                    <meta property="og:url" content="https://barso.uz/"/>
                    <meta property="og:site_name" content="barso.uz"/>
                    <meta property="article:published_time" content="2022-07-14T15:09:21+00:00"/>
                    <meta property="article:modified_time" content="2022-10-08T10:47:07+00:00"/>
                    <meta name="author" content="Barso.uz"/>
                </Helmet>
            );
        }

        return (
            <Helmet htmlAttributes={{lang: 'ru'}}>
                <meta charSet="utf-8"/>
                <title>Barso &#8212; Металлические стеллажи в Ташкенте - стеллаж паллетный - торговый стеллаж</title>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
                <meta name="description"
                      content="Металлические стеллажи в Ташкенте от компании Barso с высококачественными металлическими стеллажами, складами, барьерами, тележка, Скамейка и ограждениями"/>
                <meta name="keywords"
                      content="Стеллаж,паллетный,стеллаж,стеллаж,паллетный,металический,стеллаж,металлический,стелаж,торговый,стеллаж,полка,металлический,металлический,шкаф,раздевальный,шкаф,шкаф,для,раздевалки,верстак,шкаф,сейф,пожарный,щит"/>

                <link rel="canonical" href="https://barso.uz/"/>

                <meta property="og:locale" content="ru_RU"/>
                <meta property="og:type" content="article"/>
                <meta property="og:title" content="Barso &#8212; Металлические стеллажи в Ташкенте - стеллаж паллетный - торговый стеллаж"/>
                <meta property="og:description"
                      content="Металлические стеллажи в Ташкенте от компании Barso с высококачественными металлическими стеллажами, металлическими складами, металлическими барьерами и металлическими ограждениями"/>
                <meta property="og:url" content="https://barso.uz/"/>
                <meta property="og:site_name" content="barso.uz"/>
                <meta property="article:published_time" content="2022-07-14T15:09:21+00:00"/>
                <meta property="article:modified_time" content="2022-10-08T10:47:07+00:00"/>
                <meta name="author" content="Barso.uz"/>
            </Helmet>
        );
    }

    render() {
        return (
            <div>
                <LocaleProvider>
                    <ScrollToTop
                        smooth
                        viewBox="0 0 24 24"
                        svgPath="M12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22M12,7L7,12H10V16H14V12H17L12,7Z"
                    />
                    {this.getTags}
                    <Router>
                        <div>
                            <Header/>
                            <Switch>
                                <Route path='/' component={HomeComponent} exact/>
                                <Route path='/contact' component={Contact}/>
                                <Route path='/about' component={About}/>
                                <Route path='/product/'
                                       strict={false}
                                       exact={true}
                                       component={ProductComponent}/>
                                <Route path='/category/'
                                       strict={false}
                                       exact={true}
                                       component={ProductsComponent}/>

                                <Route path='/category-c'
                                       strict={false}
                                       exact={true}
                                       component={CategoryComponent}/>

                                <Route path='/category-c/:categoryId?/'
                                       strict={false}
                                       exact={true}
                                       component={CategoryComponent}/>
                                <Route path='/category-c/:categoryId/:subcategoryId'
                                       strict={false}
                                       exact={true}
                                       component={SubcategoryComponent}/>
                            </Switch>
                            {this.props.children}
                            <Footer/>
                        </div>
                    </Router>
                    <Notification/>
                </LocaleProvider>
            </div>
        )
    }
}

export default App;
